import React from "react"

import Layout from "../../components/Layout"
import "../../styles/services.scss"
import News from "../../components/News"
import OdooTopSection from "../../components/OdooTopSection"
import Dynamics365OptimizationImageWithTextSection from "../../components/Dynamics365OptimizationImageWithTextSection"
import WhyChooseVisiomateforD365 from "../../components/WhyChooseVisiomateforD365"
import RecentSuccessStories from "../../components/RecentSuccessStories"
import Dynamics365IntegrationSimplified from "../../components/Dynamics365IntegrationSimplified"
import Wethoughtyoumighthavequestions from "../../components/Wethoughtyoumighthavequestions"
import { graphql } from "gatsby"
import img1 from "../../images/D365/xstackintegration1.png"
import img2 from "../../images/DistributionD365/dist_top_header.png"
const Index = ({ data }) => {
  return (
    <div className="dynamics-parent-class">
      <div className="dynamics365Integration">
        <Layout pageTitle={"Visiomate - Integration with Dynamics 365"}
        descrip = {"Integrate your favorite tools with Dynamics 365 to enhance productivity, automate workflows, and gain a 360-degree view of your business. Explore the possibilities today!"}>
          <OdooTopSection
            headerData={{
              title:
                "Leverage our expertise for seamless integration of Dynamics 365 with other applications, a key strength that enhances the capabilities of your business.",
            }}
            data={data}
          />
          <Dynamics365OptimizationImageWithTextSection
            content={{
              heading:
                "Empowering the Core of Your Business by Seamlessly Integrating All Components Together",
              description:
                "Integration middleware elevates Dynamics 365 by seamlessly connecting data, ensuring real-time visibility, and optimizing efficiency. With automated processes, cost-effective operations, and enhanced security, it streamlines Dynamics 365, promoting agility and scalability. This middleware accelerates implementation, fosters compliance, and provides centralized management for a responsive and competitive ecosystem.",
            }}
            data={data}
          />
          <WhyChooseVisiomateforD365
            dataFormImages={data}
            data={{
              title: "Why Choose Visiomate for D365 Integration?",
              headings: [
                {
                  heading: "Platform Understanding",
                  description:
                    "Integration experts have an in-depth understanding of the D365 platform, including its data structure, APIs, and workflows. This knowledge is essential for designing integrations that align with D365's architecture and optimize performance.",
                },
                {
                  heading: "Experience with Diverse Systems",
                  description:
                    "Expert teams have experience integrating D365 with a wide range of third-party applications, databases, and platforms. This diverse experience equips them to handle various integration scenarios and challenges.",
                },
                {
                  heading: "Efficient Data Mapping",
                  description:
                    "Data mapping is a critical aspect of integration. Experts excel in mapping data fields accurately, ensuring consistency and reliability in data transfer between systems.",
                },
                {
                  heading: "Error Handling and Troubleshooting",
                  description:
                    "Integrations can encounter issues, and experts are well-versed in error handling and troubleshooting. Their ability to quickly identify and resolve issues minimizes downtime and ensures a reliable integration process.",
                },
              ],
            }}
          />
          <RecentSuccessStories
            data={{
              title: "Recent Success Stories ",
              Text: [
                {
                  description:
                    "An integrated solution for Dynamics 365 users, it offer seemless integration of invetory and sales between D365 and Xstack.",
                  image: img1
                },
                {
                  description:
                    "Developed a bulk distribution Utility for bulk internal transfer and wholesales order optimization.",
                  image: img2
                },
              ],
            }}
            dataFormImages={data}
          />
          <Dynamics365IntegrationSimplified />
          <Wethoughtyoumighthavequestions
            data={[
              {
                question:
                  "What integration options are available for Dynamics 365?",
                answer:
                  "Dynamics 365 offers various integration options to connect with external systems, applications, and data sources, including:",
                answer1:
                  "- APIs (Application Programming Interfaces): RESTful APIs and SOAP web services allow for real-time data exchange and integration with third-party systems.",
                answer2:
                  "- Azure Integration Services: Azure Logic Apps, Azure Functions, and Azure Service Bus provide scalable and flexible integration capabilities for connecting D365 with other Azure services and external applications.",
                answer3:
                  "- Data Integration: Dynamics 365 Data Integration tools, such as Data Export Service and Data Integrator, enable bi-directional data synchronization between D365 and external databases, data warehouses, or analytics platforms.",
                answer4:
                  "- Custom Development: Custom integrations can be developed using .NET development frameworks, Azure SDKs, or Microsoft Power Platform tools like Power Automate (formerly Microsoft Flow) and Power Apps.",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "How can I integrate Dynamics 365 with other Microsoft products and services?",
                answer:
                  "Dynamics 365 seamlessly integrates with other Microsoft products and services, allowing for a unified and connected ecosystem. Integration options include:",
                answer1:
                  "- Office 365 Integration: Dynamics 365 integrates with Office 365 applications such as Outlook, SharePoint, Excel, and Teams for enhanced collaboration, communication, and productivity.",
                answer2:
                  "- Power Platform Integration: Dynamics 365 integrates with Microsoft Power Platform components, including Power BI for analytics, Power Automate for workflow automation, and Power Apps for app development, enabling organizations to extend and customize D365 capabilities.",
                answer3:
                  "- Azure Services Integration: Dynamics 365 leverages Azure services such as Azure Active Directory, Azure Blob Storage, and Azure Cosmos DB for identity management, data storage, and analytics, facilitating seamless integration with Azure-based solutions.",
                answer4: "",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "Can Dynamics 365 integrate with third-party applications and systems?",
                answer:
                  "Yes, Dynamics 365 supports integration with a wide range of third-party applications, systems, and services. Organizations can leverage APIs, Azure Integration Services, Data Integration tools, and custom development to connect D365 with external CRMs, ERPs, marketing automation platforms, e-commerce systems, payment gateways, and more.",
                answer1: "",
                answer2: "",
                answer3: "",
                answer4: "",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "What are the key considerations for successful integration with Dynamics 365?",
                answer:
                  "Some key considerations for successful integration with Dynamics 365 include:",
                answer1:
                  "- Clearly defining integration requirements, objectives, and success criteria.",
                answer2:
                  "- Assessing compatibility and suitability of integration options based on business needs and technical constraints.",
                answer3:
                  "- Planning for data mapping, transformation, and validation to ensure data accuracy and consistency across integrated systems.",
                answer4:
                  "- Implementing security measures, authentication mechanisms, and access controls to protect sensitive data and ensure compliance with regulatory requirements.",
                answer5:
                  "- Testing integration scenarios, error handling, and performance under various conditions to validate functionality and reliability.",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "How can I get assistance with integration projects involving Dynamics 365?",
                answer:
                  "Organizations can seek assistance with integration projects involving Dynamics 365 from Microsoft Certified Partners, system integrators, and consultants with expertise in D365 integration. These professionals can provide guidance, best practices, and implementation services to ensure successful integration projects.",
                answer1: "",
                answer2: "",
                answer3: "",
                answer4: "",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
            ]}
            title="We thought you might have questions"
          />
          <News isAbout={true} career={true} />
        </Layout>
      </div>
    </div>
  )
}

export default Index
export const query = graphql`
  query integrationImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odooimg: file(relativePath: { eq: "Maskgroupcopy.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    RSSImages1: file(relativePath: { eq: "D365/xstackintegration1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }  
    RSSImages2: file(relativePath: { eq: "DistributionD365/dist_top_header.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SecondDivImg: file(
      relativePath: {
        eq: "still-life-business-roles-with-various-mechanism-pieces1copy.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
